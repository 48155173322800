import React from 'react';
import styled from 'styled-components';

import { IconSearch } from '@dop/ui-icons/svg/search';
import { additionUnit } from '@dop/shared/styleHelpers/unitCalculationHelpers';
import { mapToComponent } from '@dop/shared/helpers/reactHelpers';
import { isFunction } from '@dop/shared/helpers/functional';
import {
	mq,
	threeColumnGridBreakpoint,
	twoColumnGridBreakpoint,
	small,
} from '@dop/shared/styleHelpers/mediaQueries';
import { IconChevronRight } from '@dop/ui-icons/svg/chevronRight';
import { addTestSelector } from '@dop/shared/helpers/testSelector';
import { LayoutGridContainer } from '@dop/shared/components/layout/layoutGridContainer';
import { createUnderliner } from '@dop/shared/components/link/blockElements';
import { AccHide } from '@dop/shared/components/accHide/accHide';
import { Media } from '@dop/shared/components/media/media';
import { noBullets } from '@dop/shared/styleHelpers/list';
import { TextBlock } from '@dop/ui-primitives/typography/TextBlock';
import { getSearchURL } from '@/globals/dataCollector/searchDataCollector/functions/getSearchURL';

import { grijs, zwart } from '../../../styles/colors';
import { ColumnHalf } from '../../layout/columnHalf';
import { Heading } from '../heading';
import { cleanButton } from '../../../styles/mixins/button';
import { defaultTheme, space } from '../../../styles/defaultTheme';
import { Link } from '../../link/link';
import { hoverEtc } from '../../../styles/mixins/pseudo';
import { useRouter } from 'next/navigation';
import { usePageDataSelector } from '@/globals/dataProviders/usePageDataSelector';
import { getIn } from '@dop/shared/helpers/immutableHelpersWithArgs';
import { pushSearchResultEvent } from '@/globals/analytics/events/pushSearchEvent';
import { getSearchState } from '@/globals/dataCollector/searchDataCollector/functions/getSearchState';
import { useSearchHistory } from '@/sections/search/useSearchHistory';
import { usePageHistory } from '@/globals/pageWrappers/usePageHistory';

export const uiHomeSearchInputField = 'uiHomeSearchInputField';
export const uiHomeSearchSubmitButton = 'uiHomeSearchSubmitButton';

const TopTaskHeading = styled(Heading).attrs(() => ({
	level: 2,
	styleLevel: 3,
}))`
	color: inherit;
	font-weight: normal;
	margin: 0.2em 0 1rem 0;
`;

const TopTaskLink = styled(Link)`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	border: none;
	color: var(--color-text-secondary);

	${hoverEtc`
		fill: currentColor;
		color: var(--color-text-secondary-hover);
	`};
`;

TopTaskLink.Underliner = createUnderliner(TopTaskLink);

const TopTaskListItem = styled.li`
	display: inline-block;
	width: 100%;
	font-weight: bold;
`;

const ChevronStyled = styled(IconChevronRight)`
	width: 1.2rem;
	height: 1.2rem;
	margin: 0.2rem 0.6rem 0 -0.2rem;
	fill: currentColor;
`;

const HomeTopTask = ({ href, text }) => {
	return (
		<TopTaskListItem>
			<TopTaskLink
				to={href}
				linkType="mostViewed"
				{...addTestSelector('uiMostViewedLink')}
			>
				<Media.Figure>
					<ChevronStyled />
				</Media.Figure>
				<Media.Text>
					<TopTaskLink.Underliner>{text}</TopTaskLink.Underliner>
				</Media.Text>
			</TopTaskLink>
		</TopTaskListItem>
	);
};

const HomeTopTaskListStyled = styled.ul`
	${noBullets};
	column-gap: ${space};

	${mq({ from: small })`
		column-count: 2;
	`};

	${mq({ from: twoColumnGridBreakpoint })`
		column-count: 1;
	`};

	${mq({ from: threeColumnGridBreakpoint })`
		column-count: 2;
	`};
`;

const HomeTopTaskList = () => {
	const linkList = usePageDataSelector(getIn(['topTasks', 'linkList']));

	return (
		<HomeTopTaskListStyled>
			{mapToComponent(HomeTopTask)(linkList)}
		</HomeTopTaskListStyled>
	);
};

const HomeTopTasksBlock = () => {
	return (
		<React.Fragment>
			<TopTaskHeading>{'Veel bekeken:'}</TopTaskHeading>
			<HomeTopTaskList />
		</React.Fragment>
	);
};

const HomeSiteAnkeilerStyled = styled.div`
	flex: none;
	margin: 0 0 ${additionUnit(space)} 0;
`;

const HomeSiteAnkeilerParagraph = styled.p`
	margin: 0;
`;

/* eslint-disable no-magic-numbers */
const HomeSiteAnkeiler = () => {
	return (
		<HomeSiteAnkeilerStyled>
			<HomeSiteAnkeilerParagraph>
				{'Informatie en advies van de overheid voor ondernemers'}
				<br />
				{'door KVK, Belastingdienst, RVO, CBS, UWV en andere' +
					String.fromCharCode(160) +
					'overheidsorganisaties.'}
			</HomeSiteAnkeilerParagraph>
		</HomeSiteAnkeilerStyled>
	);
};
/* eslint-enable no-magic-numbers */

const homeSearchInputHeight = '2.6rem';

const HomeSearchFormStyled = styled.form.attrs(() => ({
	action: '/zoeken',
	method: 'post',
}))`
	flex: none;
	margin-bottom: 0.5rem;
`;

const HomeSearchInput = styled.input.attrs(() => ({
	type: 'text',
}))`
	height: ${homeSearchInputHeight};
	border: 1px solid ${zwart};
`;
const HomeSearchSubmit = styled.button.attrs(() => ({
	type: 'submit',
}))`
	${cleanButton};
	display: block;
	height: ${homeSearchInputHeight};
	width: ${homeSearchInputHeight};
	background-color: ${grijs.darkest};
	padding: 0.7rem;
`;

const IconSearchStyled = styled(IconSearch)`
	width: 100%;
	height: 100%;
	fill: white;
`;

const HomeSearchForm = class extends React.Component {
	constructor(props) {
		super(props);

		this.state = { query: props.defaultValue || '' };

		this.handleSubmit = (event) => {
			event.preventDefault();
			if (isFunction(this.props.onSubmit)) {
				this.props.onSubmit({
					query: this.state.query,
				});
			}
		};

		this.handleChange = ({ currentTarget }) => {
			const { value } = currentTarget;
			this.setState({ query: value });
		};
	}
	render() {
		return (
			<HomeSearchFormStyled onSubmit={this.handleSubmit}>
				{this.props.children({
					inputProps: { value: this.state.query, onChange: this.handleChange },
				})}
			</HomeSearchFormStyled>
		);
	}
};

const homeSearchInputId = 'homeSearchInput';
export const HomeSearch = (props) => {
	const searchHistory = useSearchHistory();
	const { push } = useRouter();
	const [pageHistory] = usePageHistory();

	const handleSearchStarted = React.useCallback(
		({ query = '' }) => {
			const url = getSearchURL({ term: query, facetSlug: 'all', page: 1 });
			const searchState = getSearchState(url);
			push(url);
			pushSearchResultEvent({
				searchState: searchState,
				searchHistory: searchHistory,
				pageHistory,
			});
		},
		[push, searchHistory, pageHistory]
	);

	return (
		<HomeSearchForm
			onSubmit={handleSearchStarted}
			defaultValue={props.defaultValue}
		>
			{({ inputProps }) => (
				<React.Fragment>
					<TextBlock as="label" htmlFor={homeSearchInputId} $accHide>
						{'Zoeken in Ondernemersplein'}
					</TextBlock>
					<Media>
						<Media.Text>
							<HomeSearchInput
								{...inputProps}
								id={homeSearchInputId}
								autoComplete="off"
								placeholder="Zoeken in Ondernemersplein"
								{...addTestSelector(uiHomeSearchInputField)}
							/>
						</Media.Text>
						<Media.Figure>
							<HomeSearchSubmit {...addTestSelector(uiHomeSearchSubmitButton)}>
								<IconSearchStyled />
								<AccHide>{'Zoeken'}</AccHide>
							</HomeSearchSubmit>
						</Media.Figure>
					</Media>
				</React.Fragment>
			)}
		</HomeSearchForm>
	);
};

const SearchColumnStyled = styled(ColumnHalf)`
	${mq({ from: twoColumnGridBreakpoint })`
		padding-right: 2rem;
	`};
`;

const TopTaskColumnStyled = styled(ColumnHalf)`
	${mq({ to: twoColumnGridBreakpoint })`
		margin-top: 2rem;
	`};
`;

export const HomeTopTasksStyled = styled.div`
	inline-size: 100%;
	background-color: var(--color-negative-primary);
	padding-block: ${defaultTheme.halfSpace};
`;

export const HomeTopTasks = () => (
	<HomeTopTasksStyled {...addTestSelector('uiHomeTopTasksBar')}>
		<LayoutGridContainer>
			<SearchColumnStyled>
				<HomeSiteAnkeiler />
				<HomeSearch />
			</SearchColumnStyled>
			<TopTaskColumnStyled>
				<HomeTopTasksBlock />
			</TopTaskColumnStyled>
		</LayoutGridContainer>
	</HomeTopTasksStyled>
);
